//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Reporter',
    props: [
        'entity_type',
        'entity_id'
    ],
    data() {
        return {
            title: this.getTitle(this.entity_type),
            selectedReportType: 1,
            currentState: 1,
            reason: '',
            cancelLabel: 'Cancel',
            nextLabel: 'Next',
            isSending: false,
            reportTypes: [
                {
                    label: 'Nudity',
                    value: 1
                },
                {
                    label: 'Violence',
                    value: 2
                },
                {
                    label: 'Harassment',
                    value: 3
                },
                {
                    label: 'Suicidal',
                    value: 4
                },
                {
                    label: 'False Information',
                    value: 5
                },
                {
                    label: 'Spam',
                    value: 6
                },
                {
                    label: 'Unauthorized Sales',
                    value: 7
                },
                {
                    label: 'Hate Speech',
                    value: 8
                },
                {
                    label: 'Terrorism',
                    value: 9
                },
                {
                    label: 'Non Golf Related',
                    value: 10
                },
                {
                    label: 'Other',
                    value: 11
                }
            ]
        }
    },
    methods: {
        /**
         * Get title of the modal.
         */
        getTitle(entity_type) {
            switch (entity_type) {
                case 1:
                    return 'Group'
                case 2:
                    return 'Event'
                case 3:
                    return 'Post'
                case 4:
                    return 'Comment'
                case 5:
                    return 'Media'
            }
        },
        /**
         * Moves on to the next reporting form.
         */
        next() {
            if (this.currentState === 1) {
                this.currentState = 2;
                this.changeLabels();
                return;
            }

            if (this.currentState === 2) {
                this.sendReport();
                this.changeLabels();
            }
        },

        /**
         * Sends report to the server.
         */
        sendReport() {
            this.isSending = true;
            this.$http({
                method: 'POST',
                url: 'reports',
                data: {
                    entity_type: this.entity_type,
                    entity_id: this.entity_id,
                    report_type: this.selectedReportType,
                    reason: this.reason
                }
            }).then((response) => {
                switch (response.data['status']) {
                    case 'report_created':
                        this.$emit('close');
                        this.$q.notify({
                            message:"Your report has been successfully sent to the administrators for review. Thank you.",
                            color: 'positive',
                            icon: 'done'
                        });
                    break;
                    default:
                        this.currentState = 1;
                        this.$q.notify({
                            message: 'We cannot process your report. Please try again.',
                            color: 'negative',
                            icon: 'error'
                        });
                    break;
                }
            })
        },

        /**
         * The cancel function.
         */
        cancel() {
            if (this.currentState === 1) {
                this.$emit('close');
                return;
            }

            this.currentState = 1;
            this.changeLabels();
        },

        /**
         * Change labels of the buttons.
         */
        changeLabels() {
            if (this.currentState === 1) {
                this.cancelLabel = 'Cancel';
                this.nextLabel = 'Next'
            }

            if (this.currentState === 2) {
                this.cancelLabel = 'Back';
                this.nextLabel = 'Send';
            }
        },
    }
}
