//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from '@/components/Avatar'
import Reporter from '@/components/Reporter/Reporter'
export default {
  name: "GroupNamestrip",
  components: { Avatar, Reporter },
  props: [
    'name',
    'privacy', 
    'id',
    'membersCount',
    'isMember',
    'group_captain',
    'group_captain_id',
    'isOwner',
    'isAdmin'
  ],
  data() {
    return {
      isSharing: false,
      showReporter: false
    }
  },
  methods: {
    shareGroup() {
      this.$q.dialog({
        title: 'Share ' + this.name + ' to your wall?',
        message: 'When sharing this group, it will be posted on your wall and your friends will get to see it. <br><br>Continue?' +
        '<br><br><div class="text-caption text-grey-7">The groups that are set as private will not be visible from other players if they are not invited by the captain or do not have an active invite.</div>',
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        this.isSharing = true;
        this.$http({
          url: 'posts/share_entity',
          method: 'POST',
          data: { entity_id: this.id }
        }).then((response) => {
          this.isSharing = false;
          switch (response.data['status']) {
            case 'share_interval':
              this.$q.notify({
                message: 'Please wait for a while when sharing the same Group.',
                color: 'primary'
              });
            break;
            case 'share_successful':
              this.$q.notify({
                message: 'This group was successfully shared on your wall and can now be seen by your friends.',
                color: 'blue'
              });
            break;
          }
        }).catch((err) => {
          this.isSharing = false;
          switch (err.response.status) {
            case 403:
              this.$q.notify({
                message: 'You do not have the permission to share this group.',
                color: 'warning'
              });
            break;
            case 404:
              this.$q.notify({
                message: 'The group that you are trying to share was not found.',
                color: 'negative'
              });              
            break;
            case 500:
              this.$q.notify({
                message: 'An error has occurred while trying to share. Please try again later.',
                color: 'negative'
              });   
            break;
          }
        })
      }).onCancel(() => {
        //
      }).onDismiss(() => {
        this.isSharing = false;
      })
    },
    updateGroup(id){
      this.$router.push({name:'GroupSettings',params:{id:id}})
    },
    leave() {
        this.$q.dialog({
          message: 'Are you sure you want to leave this group?',
          title: "Leave Group",
          icon: "person_remove",
          cancel: true,
          persistent: true
        }).onOk(() => {
          this.$q.loading.show({
            message: 'Leaving group..'
          });
          this.$http({

            url: 'groups/' + this.id + '/leave',
            method: 'POST'
          }).then((response) => {
            this.$q.loading.hide();

            if (response.data['status'] == 'not_member') {
              this.$q.notify({
                message: 'You are not a member of this group.',
                color: 'negative'
              });
            } 

            if (response.data['status'] == 'group_left') {
              window.location.reload();
              this.$q.notify({
                message: 'You have left this group.',
                color: 'positive'
              });
            }
          })
          .catch(err => {
            console.log(err)
          })
        }).onCancel(() => {
          //
        })
    },
    trash() {
        this.$q.dialog({
          message: 'Are you sure you want to delete this group?',
          title:'Delete Group',
          cancel: true,
        }).onOk(() =>{
          this.$http({
                url: 'groups/' + this.$route.params.id,
                method: 'DELETE'
            }).then((response) => {
                this.isLoading = false;
                switch (response.data['status']) {
                    case 'group_deleted':
                       this.$q.notify({
                         message:'You have successfully deleted the group.',
                         color:'primary'
                       });
                       this.$router.push({name:'Groups'});
                    break;
                }
            }).catch((err) => {
                this.isLoading = false;
                switch (err.response.status) {
                    case 404:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 403:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                    case 500:
                        this.$router.push({
                            name: 'NotFound404'
                        });
                    break;
                }
            });
        })
            
    }
  }
}
