//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Avatar",
    props: [
        'size',
        'id',
        'isOwner',
        'name'
    ],
    data() {
        return {
            imgSize: this.size ? this.size : "150",
            file: null,
            isLoading: false,
            isMouseOver: false,
            showProfilePhoto: false,
            image:  require('@/assets/default_profile.webp')
        }
    },
    methods: {
        onClick(){
            this.showProfilePhoto = !this.showProfilePhoto
            return
        },
        onImgError(){
           this.$q.notify({
               message:'No Media Available',
               color:'negative',
               icon: 'error'
           })
        },
        switchState(state) {
            this.showProfilePhoto = false
            this.$store.commit('profile/SwitchState',state)
            this.$store.commit('profile/isUpdateProfileState', true)
        },
        getLocalFiles() {
            if (this.isOwner) {
                this.$refs.filePicker.pickFiles();
            }
        },
        loadImage() {
            this.isLoading = true;
            if(this.id != this.$store.state.user.id || this.$store.state.user.profile_photo == '' ){
                this.$store.state.profile.profile_photo = '';
                this.payload = {
                    user_id: this.id,
                }
                this.$store.dispatch('profile/getProfilePhoto', this.payload)
                .then((response) =>{
                    if(response.status == 'success'){
                        this.image = response.image
                        if (this.id == this.$store.state.user.id){
                            this.$store.state.user.profile_photo = response.image
                        }
                    }
                })
            }else{
                this.$store.commit('profile/UpdateStateProfilePhoto', this.$store.state.user.profile_photo)
                this.image = this.$store.state.user.profile_photo
            }
            this.isLoading = false;
        },
        uploadImage() {
            if (!this.file) {
                return;
            }
           this.$q.dialog({
               title:'Change Profile Picture',
               message: 'Are you sure you want to use this as your profile picture?',
               cancel: true,
           })
           .onOk(() => {
                this.isLoading = true;
                var formData = new FormData();
                formData.append('file', this.file);
                formData.append('user_id', this.$store.state.user.id);
                this.payload = {
                    data: formData
                }
                this.$store.dispatch('profile/uploadProfilePhoto',this.payload)
                .then((status) =>{
                    if(status == 'upload_success'){
                        this.$q.notify({
                            message:'Profile Photo successfuly updated',
                            color:'positive',
                            icon: 'check_circle'
                        })
                        location.reload()
                    }else{
                        this.src = this.$store.state.app.defaultProfilePhoto
                    }
                })
                this.isLoading = false;
           })
           .onCancel(() => {})
           .onDismiss(() => {})
        }
    },
    created() {
        this.loadImage();

    }
}
